<template>
  <base-container>
    <template v-slot:header>
      <v-btn class="mx-2" @click="$router.replace({ name: 'dashboard' })" color="accent" :disabled="loading">Voltar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="mr-5" @click="saveData" color="accent">
        {{ computedSaveBtn }}
      </v-btn>
    </template>
    <v-form ref="form" v-model="valid" lazy-validation="lazy-validation">
      <v-card class="mb-4" :loading="loading">
        <v-card-title><span>Conta</span></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :disabled="true"
                label="Nome completo"
                v-model="user.displayName"
              />
            </v-col>
            <v-col v-if="user.email" cols="12">
              <v-text-field
                :disabled="true"
                label="Email"
                v-model="user.email"
              />
            </v-col>
            <v-col v-if="user.phoneNumber" cols="12">
              <v-text-field :disabled="true" label="Telefone" type="tel"
                v-mask="['+## (##) ####-####', '+## (##) #####-####']" v-model="user.phoneNumber"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-4" :loading="loading">
        <v-card-title><span>Cadastro</span></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" :rules="nameRules" @keypress.enter="$refs.document.focus()"
                label="Nome completo" ref="name" v-model="doc.name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" :label="doc.document.length > 14 ? 'CNPJ' : 'CPF'"
                :rules="documentRules" @keypress.enter="$refs.role.focus()" ref="document" type="tel"
                v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="doc.document"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-select :disabled="!editable" :items="$store.getters.accountRoles"
                :rules="[v => !!v || 'Campo obrigarório']" @keypress.enter="$refs.genre.focus()" label="Função"
                ref="role" v-model="doc.role"></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-select :disabled="!editable" :items="$store.getters.accountGenre" @keypress.enter="$refs.email.focus()"
                label="Gênero" ref="genre" v-model="doc.genre"></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" :rules="emailRules" @keypress.enter="$refs.phone.focus()"
                label="E-mail" ref="email" type="email" v-model="doc.email"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" @keypress.enter="$refs.zipcode.focus()" label="Telefone" ref="phone"
                type="tel" v-mask="['(##) ####-####', '(##) #####-####']" v-model="doc.phone"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" :rules="zipcodeRules" @blur="getZipcode" @keypress.enter="getZipcode"
                label="CEP" ref="zipcode" type="tel" v-mask="'#####-###'" v-model="doc.address.zipcode"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" :rules="[v => !!v || 'Campo obrigarório']"
                @keypress.enter="$refs.state.focus()" label="Município" ref="city" v-model="doc.address.city">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete :disabled="!editable" :filter="customFilter" :items="stateList"
                :rules="[v => !!v || 'Campo obrigarório']" @keypress.enter="$refs.street.focus()" item-text="name"
                label="Estado" no-data-text="Nenhum resultado" ref="state" return-object="return-object"
                v-model="doc.address.state"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" @keypress.enter="$refs.number.focus()" label="Logradouro" ref="street"
                v-model="doc.address.street"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" @keypress.enter="$refs.district.focus()" label="Número" ref="number"
                v-model="doc.address.number"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" @keypress.enter="$refs.complement.focus()" label="Bairro"
                ref="district" v-model="doc.address.district"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field :disabled="!editable" @keypress.enter="$refs.complement.blur()" label="Complemento"
                redisabledf="complement" v-model="doc.address.complement"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </base-container>
</template>

<script>
import BaseContainer from "@/components/BaseContainer.vue";

import utils from "../../plugins/utils";
import { auth, db } from "../../plugins/google/firebase";
import { mask } from "vue-the-mask";
import getCep from "../../plugins/getCep";
import $store from "@/store";

const { COLLECTION_ACCOUNTS } = $store.getters;
const SOURCE = { source: "cache" };

// const API_URL =
//   process.env.NODE_ENV !== "production"
//     ? process.env.VUE_APP_API_LOCAL
//     : process.env.VUE_APP_API_URL;

export default {
  name: "profile",
  components: { BaseContainer },
  directives: { mask },

  data: () => ({
    docRef: "",
    editable: false,
    loading: false,
    valid: true,
    user: {
      displayName: "",
      email: "",
      phoneNumber: "",
    },
    doc: {
      address: {
        complement: "",
        coords: { latitude: 0, longitude: 0 },
        city: "",
        district: "",
        number: "",
        state: { initials: "", name: "" },
        street: "",
        zipcode: "",
      },
      document: "",
      email: "",
      genre: "",
      name: "",
      phone: "",
      role: "",
    },
    nameRules: [
      (v) => !!v || "Campo obrigarório",
      (v) => (v && v.length > 2) || "Mínimo 3 caracteres",
    ],
    documentRules: [],
    emailRules: [(v) => utils.validateEmail(v) || "E-mail deve ser válido"],
    zipcodeRules: [
      (v) => !!v || "Campo obrigarório",
      (v) => (v && v.length > 8) || "CEP deve ser válido",
    ],
    stateList: utils.ufs,
  }),

  mounted() {
    this.getUserData();
  },

  computed: {
    computedSaveBtn() {
      return this.editable ? "Salvar" : "Editar";
    },
  },

  methods: {
    getUserData() {
      const user = auth.currentUser;
      if (user && user.uid) {
        const data = {
          displayName: user.displayName || "",
          email: user.email || "",
          phoneNumber: user.phoneNumber || "",
        };

        user.providerData.forEach((val) => {
          if (!data.displayName && val.displayName) {
            data.displayName = val.displayName;
          }
          if (!data.email && val.email) {
            data.email = val.email;
          }
          if (!data.phoneNumber && val.phoneNumber) {
            data.phoneNumber = val.phoneNumber;
          }
        });

        this.user = data;
        this.getDocRef(user.uid);
      }
    },

    getDocRef(uid) {
      if (uid) {
        this.getDataFromApi(uid);
      } else {
        this.docRef = db.collection(COLLECTION_ACCOUNTS).doc(uid);
        this.editable = true;
        setTimeout(() => {
          this.$refs.name.focus();
        }, 250);
      }
    },

    getDataFromApi(docRef) {
      this.docRef = db.collection(COLLECTION_ACCOUNTS).doc(docRef);
      this.docRef
        .get()
        .then((snapshot) => {
          if(!snapshot.exists){
            return;
          }
          const newDoc = snapshot.data();
          newDoc.document = utils.docMask(newDoc.document);
          this.doc = newDoc;
        })
        .catch(() => {
          this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
        });
    },

    getZipcode() {
      if (this.loadingAddress) {
        return;
      }
      this.loadingAddress = true;
      getCep(utils.cepMask(this.doc.address.zipcode, true))
        .then((d) => {
          if (d && d.cep) {
            d.logradouro && (this.doc.address.street = d.logradouro);
            d.complemento && (this.doc.address.complement = d.complemento);
            d.bairro && (this.doc.address.district = d.bairro);
            if (d.municipio) {
              this.doc.address.city = d.municipio.nome;
              if (d.municipio.uf) {
                this.doc.address.state = {
                  initials: d.municipio.uf.sigla,
                  name: d.municipio.uf.nome,
                };
              }
            }
          }
        })
        .catch(() => null)
        .finally(() => {
          this.loadingAddress = false;
          setTimeout(() => this.$refs.city.focus(), 250);
        });
    },

    customFilter(item, queryText) {
      const one = item.name.toLowerCase();
      const two = item.initials.toLowerCase();
      const searchText = queryText.toLowerCase();

      return one.indexOf(searchText) > -1 || two.indexOf(searchText) > -1;
    },

    async saveData() {
      if (!this.editable) {
        this.editable = true;
        return;
      }

      if (!this.$refs.form.validate()) {
        return;
      }
      if (!await this.validateData()) {
        return;
      }


      try {
        const doc = this.getStateData();
        doc.updatedAt = new Date().toJSON();

        if (this.inserting) {
          doc.createdAt = doc.updatedAt;
        }

        if (auth.currentUser) {
          this.user.displayName = doc.name;
          await auth.currentUser.updateProfile({
            displayName: doc.name,
          });
        }

        this.docRef.set(doc, { merge: true });
        this.editable = false;
        this.inserting = false;
        this.$snackbar.show({ title: "Dados salvos com sucesso" });
      } catch {
        this.$snackbar.show({ title: "Erro ao salvar dados, sem conexão" });
      }

      this.editable = false;
    },

    getStateData() {
      return {
        address: this.doc.address,
        document: utils.docMask(this.doc.document, true),
        email: this.doc.email,
        genre: this.doc.genre,
        name: this.doc.name,
        phone: utils.phoneMask(this.doc.phone, true),
        role: this.doc.role,
      };
    },

    async validateData() {
      const { document, email } = this.doc;
      const docRef = db.collection(COLLECTION_ACCOUNTS);
      const docQuery = docRef.where(
        "document",
        "==",
        utils.docMask(document, true),
      );
      const emailQuery = docRef.where("email", "==", email);

      const docSnapshot = await docQuery.get(SOURCE);
      const emailSnapshot = await emailQuery.get(SOURCE);
      let docCount = false;
      let emailCount = false;

      docSnapshot.forEach((val) => {
        if (val.id !== this.docRef.id) {
          docCount = true;
        }
      });
      emailSnapshot.forEach((val) => {
        if (val.id !== this.docRef.id) {
          emailCount = true;
        }
      });

      if (docCount) {
        this.$snackbar.show({ title: "Documento informado já existe" });
        return false;
      }
      if (emailCount) {
        this.$snackbar.show({ title: "E-mail informado já existe" });
        return false;
      }
      return true;
    },
  },
};
</script>