<template>
  <v-container fluid="fluid" fill-height="fill-height">
    <v-layout class="flex-column">
      <div class="d-flex mb-4 mt-2 flex-wrap">
        <slot name="header"></slot>
      </div>
      <slot></slot>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "base-container",
};
</script>
